<template>
  <b-row class="match-height">
    <b-col lg="6"> <b-card
    title="Purchase Order"
  >
    <b-form
        @submit.prevent="saveSettingsProcess">
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="PO NO *"
            label-for="po_no"
            label-cols-md="12"
          >
            <b-form-input
              id="po_no"
              placeholder="PO No."
              required="required"
            />
          </b-form-group>
          <b-form-group
            label="PO Date *"
            label-for="po_date"
            label-cols-md="12"
          >
            <b-form-input
              id="po_date"
              placeholder="PO Date."
              required="required"
            />
          </b-form-group>
          <b-form-group
            label="PR No *"
            label-for="pr_no"
            label-cols-md="12"
          >
            <b-form-input
              id="pr_no"
              placeholder="PR NO."
              required="required"
            />
          </b-form-group>
          <br>
          <b-form-group
            label="Code"
            label-for="code"
            label-cols-md="12"
          >
            <b-form-input
              id="code"
              placeholder="Code"
              required="required"
            />
          </b-form-group>
          <b-form-group
            label="Name"
            label-for="name"
            label-cols-md="12"
          >
            <b-form-input
              id="name"
              placeholder="Name"
              required="required"
            />
          </b-form-group>
          <b-form-group
            label="Notes"
            label-for="notes"
            label-cols-md="12"
          >
            <b-form-input
              id="notes"
              placeholder="Notes"
              required="required"
            />
          </b-form-group>
          <b-form-group
            label="Qty"
            label-for="qty"
            label-cols-md="12"
          >
            <b-form-input
              id="qty"
              placeholder="qty"
              required="required"
            />
          </b-form-group>
          <b-form-group
            label="Unit"
            label-for="unit"
            label-cols-md="12"
          >
            <b-form-input
              id="unit"
              placeholder="Unit"
              required="required"
            />
          </b-form-group>
          <b-form-group
            label="Disc"
            label-for="disc"
            label-cols-md="12"
          >
            <b-form-input
              id="disc"
              placeholder="Disc"
              required="required"
            />
          </b-form-group>
          <b-form-group
            label="Tax"
            label-for="tax"
            label-cols-md="12"
          >
            <b-form-input
              id="tax"
              placeholder="Tax"
              required="required"
            />
          </b-form-group>
          <b-form-group
            label="Total"
            label-for="total"
            label-cols-md="12"
          >
            <b-form-input
              id="total"
              placeholder="Total"
              required="required"
            />
          </b-form-group>
          <br>
          <b-form-group
            label="Total Amount"
            label-for="total_amount"
            label-cols-md="12"
          >
            <b-form-input
              id="total_amount"
              placeholder="Total Amount"
              required="required"
            />
          </b-form-group>
          <b-form-group
            label="Disc"
            label-for="disc_"
            label-cols-md="12"
          >
            <b-form-input
              id="disc_"
              placeholder="Disc"
              required="required"
            />
          </b-form-group>
          <b-form-group
            label="Total Tax"
            label-for="total_Tax"
            label-cols-md="12"
          >
            <b-form-input
              id="total_Tax"
              placeholder="Total Tax"
              required="required"
            />
          </b-form-group>
          <b-form-group
            label="Grand Total"
            label-for="grand_total"
            label-cols-md="12"
          >
            <b-form-input
              id="grand_total"
              placeholder="Grand Total"
              required="required"
            />
          </b-form-group>
          <br>
          <b-form-group
            label="Term Of Payment"
            label-for="top"
            label-cols-md="12"
          >
            <b-form-input
              id="top"
              placeholder="Term Of Payment"
              required="required"
            />
          </b-form-group>
          <b-form-group
            label="Daily Schedule"
            label-for="daily_schedule"
            label-cols-md="12"
          >
            <b-form-input
              id="daily_schedule"
              placeholder="Daily Schedule"
              required="required"
            />
          </b-form-group>
          <b-form-group
            label="Delivery Address"
            label-for="delivery_address"
            label-cols-md="12"
          >
            <b-form-input
              id="delivery_address"
              placeholder="Delivery Address"
              required="required"
            />
          </b-form-group>
          <br>
          <b-form-group
            label="Billing Address"
            label-for="billing_address"
            label-cols-md="12"
          >
            <b-form-input
              id="billing_address"
              placeholder="Billing Address"
              required="required"
            />
          </b-form-group>
          <b-form-group
            label="Notes"
            label-for="notes_"
            label-cols-md="12"
          >
            <b-form-input
              id="notes_"
              placeholder="Notes"
              required="required"
            />
          </b-form-group>
          <b-form-group
            label="Approved By"
            label-for="approved_by"
            label-cols-md="12"
          >
            <b-form-input
              id="approved_by"
              placeholder="Approved By"
              required="required"
            />
          </b-form-group>
            <br>
          <b-form-group
            label="Original"
            label-for="original"
            label-cols-md="12"
          >
            <b-form-input
              id="original"
              placeholder="Original"
              required="required"
            />
          </b-form-group>
          <b-form-group
            label="Copy 1"
            label-for="copy_1"
            label-cols-md="12"
          >
            <b-form-input
              id="copy_1"
              placeholder="Copy 1"
              required="required"
            />
          </b-form-group>
          <b-form-group
            label="Copy 2"
            label-for="copy_2"
            label-cols-md="12"
          >
            <b-form-input
              id="copy_2"
              placeholder="Copy 2"
              required="required"
            />
          </b-form-group>
        </b-col>
        <!-- submit and reset -->
        <b-col offset-md="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
          >
            Submit
          </b-button>
        </b-col>
      </b-row>

    </b-form>

  </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BCard,
  } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import moment from 'moment'
import { getToken } from '@/auth/utils'

const userToken = getToken()
const headers = {
  'Content-Type': 'application/json',
  'X-Token-Access': `Bearer ${userToken}`,
}
const VersionCheck = ''
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
  },
  data() {
    return {
    VersionCheck,
    }
  },
  mounted() {
      this.settingsCheck()
  },
  methods: {
  settingsCheck() {
    axios
      .get('https://api.geolims.com/invoice_service/InvoiceVersion', { headers })
      .then(response => {
        if (response.data.Status === 1 && response.data.Payload.length > 0) {
          this.modalShow = true
          this.VersionCheck = response.data.Payload[0].invoiceVersionNumber
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Get Settings Success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        } else {
        this.$toast({
          component: ToastificationContent,
          props: {
              title: 'Get Settings Failed',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
        })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
              title: 'Get Settings Failed',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  saveSettingsProcess() {
      const body = {
        invoiceVersionNumber: this.VersionCheck,
      }
    axios
      .post('https://api.geolims.com/invoice_service/changeInvoiceVersion', body, { headers })
      .then(response => {
        if (response.data.Status === 1) {
          this.modalShow = false
          this.InvoiceNumber = ''
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Save Settings Success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        } else {
        this.$toast({
          component: ToastificationContent,
          props: {
              title: 'Save Settings Failed',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
        })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
              title: 'Save Settings Failed',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
    dateFormat(value, format = 'MM/DD/YYYY') {
        return moment(String(value)).format(format)
    },
  },
}
</script>
